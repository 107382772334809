.tickets-unique {
  width: 95%;
  margin: 0 auto;
}

.MTableBodyRow-root {
  background-color: black;
}

.newTicket {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 1%;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.completedTicketsHide {
  display: flex;
  font-size: 0.8em;
  justify-content: flex-start;
  margin-left: 5%;
  border-style: solid !important;
  border: 5px !important;
  border-color: black;
}

.tickets-unique > div > div > div > div > div > table > thead > tr > th:nth-child(1) {
  min-width: 3vw !important;
}

.tickets-unique > div > div > div > div > div > table > thead > tr > th:nth-child(2) {
  min-width: 30vw !important;
}

.tickets-unique > div > div > div > div > div > table > thead > tr > th:nth-child(3) {
  min-width: 15vw !important;
}

@media only screen and (max-width: 1499px) {
  .tickets-unique {
    width: 90%;
  }
}

@media only screen and (max-width: 1377px) {
  .tickets-unique {
    width: 90% !important;
  }
  .newTicket {
    margin-left: 5%;
  }
}