.accounts-management-container {
  width: 100%;
}

.accounts-management-title {
  font-size: 1.2em;
  font-weight: bold;
}

.accounts-management-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.accounts-option-btn {
  width: 15%;
  font-size: 0.5em;
  margin: 2%;
  padding: 1%;
  border: 3px solid black;
  border-radius: 10px;
  cursor: pointer;
}
