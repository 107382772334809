.notificationSettingsModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border: 0.5px solid #000;
    box-shadow: 24;
    padding: 15px;
    border-radius: 0.5rem;
}

.radioPushNotification label {
    display: flex;
}

.radioPushNotification input {
    margin-right: 10px;
}

.notificationSettingsModal p {
    margin-top: 15px;
    color: green;
}
