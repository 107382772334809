@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
.login-form {
  width: 100%;
  padding: 15%;
  background-color: white;
}

.login-form-field {
  font-size: 1em;
  width: 100%;
  margin-bottom: 5%;
}

#logo {
  width: 100%;
  display: flex;
}

.btn {
  width: 70%;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
}

.login-btn {
  display: flex;
  justify-content: center;
}

.spinning-icon {
  margin-left: 5%;
}

.spinning-icon-hide {
  display: none;
}

.login-error {
  color: #990000;
  font-size: 0.6em;
  font-weight: bold;
}

.login-success {
  color: #2ad613;
  font-size: 0.6em;
  font-weight: bold;
}

/* Overriding Material UI */

.home-login-btn {
  width: 40% !important;
  padding: 5% !important;
  color: white !important;
  background-color: #4f7822 !important;
}

.home-login-btn:hover {
  background-color: #395719 !important;
}

.login-form-tfa-hide {
  display: none;
}
.info {
  display: flex;
  flex-direction: row;
  /* align-items: baseline; */
  width: 100%;
  background-color: white;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  font-size: 0.5em;
  font-family: "Montserrat", sans-serif;
  border-bottom: 3px solid #9ecf67;
}

.stickyCover {
  position: fixed;
  top: 9%;
  width: 9%;
  max-width: 230px;
  height: 100%;
  background-color: #f7f7f7;
}

.navBarLogo {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /* margin-left: 10%; */
  color: white;
}

/* .navBarLogo:before {
  content: "Country Consulting Admin Area";
} */

.user {
  display: flex;
  justify-content: flex-end;
  width: 30%;
  margin-right: 1%;
  color: black;
}

.settings {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: white !important;
  border-color: white !important;
}

.pwBtn {
  color: white !important;
  border-color: white !important;
}

.tfaBtn {
  color: white !important;
  border-color: white !important;
}

.navBtn {
  color: white !important;
  border-color: white !important;
  margin-left: 5% !important;
}

.navBtnExport {
  color: white !important;
  border-color: white !important;
  margin-left: 2% !important;
}

.timer {
  color: white !important;
  border-color: white !important;
  margin-left: 2% !important;
  margin-right: 1% !important;
}

.hide {
  visibility: hidden;
}

.dropdown {
  display: none;
}

/* #navBarLogo {
  display: none;
} */

@media only screen and (max-width: 978px) {
  .stickyCover {
    display: none;
  }

  .info {
    padding-bottom: 2%;
    background-color: #2e2e2e;
  }

  .navBarLogo {
    margin-left: 2%;
  }

  .navBarLogo:before {
    content: none;
  }

  #navBarLogo {
    display: flex;
    margin-top: 5%;
    width: 220px;
    height: 24px;
  }

  .user {
    font-size: 1.5em;
    width: 100%;
    margin-top: 3%;
  }

  .dropdown {
    display: flex;
    color: white;
    margin-top: 1%;
    margin-right: 1.5%;
  }

  #dropdownMobile {
    background-color: #2e2e2e;
    border: 0px;
    box-shadow: none;
    text-align: center;
    justify-items: center;
    justify-self: center;
  }

  #dropdownMobile::after {
    display: none !important;
  }

  .dropdownBtn {
    background-color: #3f51b5;
    padding: 2px;
    border-radius: 5px;
  }

  .navBarBtnMobile {
    color: white !important;
    border-top: solid 1px !important;
    border-bottom: solid 1px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-radius: 0px !important;
    border-color: #2d2e2f !important;
    background-color: black !important;
    text-align: center !important;
    font-size: 0.7em !important;
    width: 100%;
  }

  .dropdown-menu {
    background-color: #1b1c14;
  }

  .iconStart {
    margin: 0;
  }
}

@media only screen and (max-width: 420px) {
  #lognavBarLogo {
    width: 220px;
    height: 24px;
  }
}
.notificationSettingsModal {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border: 0.5px solid #000;
    box-shadow: 24;
    padding: 15px;
    border-radius: 0.5rem;
}

.radioPushNotification label {
    display: flex;
}

.radioPushNotification input {
    margin-right: 10px;
}

.notificationSettingsModal p {
    margin-top: 15px;
    color: green;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}


.sideBarBody {
  background-color: #f7f7f7;
  min-width: 230px;
  width: 12%;
  max-width: 230px;
  font-family: "Montserrat", sans-serif;
}

.stickySideBar {
  position: fixed;
  min-width: 230px;
  width: 9%;
  max-width: 230px;
  background-color: #f7f7f7;
}

.sideBtn {
  width: 100%;
  color: black !important;
  justify-content: flex-start !important;
  font-size: 0.45em !important;
}

.timesheetTracker {
  color: black !important;
  font-size: 0.65em;
}

.timesheetTrackerBody {
  color: black !important;
  font-size: 0.55em;
}

.sideBtn:hover {
  background-color: white !important;
  border-color: #424345 !important;
}

.sideBtnBtm {
  width: 100%;
  color: black !important;
  font-size: 0.45em !important;
  justify-content: flex-start !important;
}

.sideBtnBtm:hover {
  background-color: white !important;
  font-size: 0.45em !important;
}

.sideBtnBtmPassword {
  width: 100%;
  color: black !important;
  font-size: 0.45em !important;
  justify-content: flex-start !important;
}

.sideBtnBtmPassword:hover {
  background-color: white !important;
  font-size: 0.45em !important;
}

.iconStart {
  margin-right: 5%;
}

.iconEnd {
  margin-left: 5%;
}

.unrelatedObjects {
  margin-top: 10%;
}

.userSettings {
  margin-top: 10%;
}

@media only screen and (max-width: 1377px) {
  .stickySideBar {
    width: 12%;
  }
}

@media only screen and (max-width: 978px) {
  .sideBarBody {
    display: none;
  }

  .stickySideBar {
    display: none;
  }

  .sideBtnBtmPassword {
    font-size: 0.7em !important;
    color: white !important;
  }
}

/* Animations */

.sidebar-dropdown-default {
  width: 100%;
  border-bottom: solid 2px black;
  border-top: solid 2px black;
  padding-bottom: 0vh;
}

.sidebar-dropdown-item-default {
  display: none;
}

.sidebar-dropdown-tickets-show {
  width: 100%;
  border-bottom: solid 2px black;
  border-top: solid 2px black;
  padding-bottom: 0vh;
  z-index: 999;
  /* animation: display-dropdown-tickets 0.5s forwards; */
}

@-webkit-keyframes display-dropdown-tickets {
  100% {
    padding-bottom: 20vh
  }
}

@keyframes display-dropdown-tickets {
  100% {
    padding-bottom: 20vh
  }
}

.sidebar-dropdown-tickets-collapse {
  width: 100%;
  border-bottom: solid 2px black;
  border-top: solid 2px black;
  padding-bottom: 20vh;
  z-index: 999;
  /* animation: collapse-dropdown-tickets 2s forwards; */
}

@-webkit-keyframes collapse-dropdown-tickets {
  50% {
    padding-bottom: 20vh;
  }

  100% {
    padding-bottom: 0vh
  }
}

@keyframes collapse-dropdown-tickets {
  50% {
    padding-bottom: 20vh;
  }

  100% {
    padding-bottom: 0vh
  }
}

.sidebar-dropdown-all-tickets-show {
  width: 100%;
  position: absolute;
  top: 10vh;
  visibility: hidden;
  opacity: 0;
  /* animation: display-dropdown-all-tickets 1.75s forwards; */
}

@-webkit-keyframes display-dropdown-all-tickets {
  60% {
    top: 10vh;
  }

  100% {
    opacity: 1;
    transition: opacity 7s ease-in-out;
    visibility: visible;
    top: 13vh
  }
}

@keyframes display-dropdown-all-tickets {
  60% {
    top: 10vh;
  }

  100% {
    opacity: 1;
    transition: opacity 7s ease-in-out;
    visibility: visible;
    top: 13vh
  }
}

.sidebar-dropdown-all-tickets-collapse {
  width: 100%;
  position: absolute;
  top: 13vh;
  visibility: visible;
  opacity: 1;
  /* animation: collapse-dropdown-all-tickets 0.75s forwards; */
}

@-webkit-keyframes collapse-dropdown-all-tickets {
  100% {
    opacity: 0;
    transition: opacity 2s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}

@keyframes collapse-dropdown-all-tickets {
  100% {
    opacity: 0;
    transition: opacity 2s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}

.sidebar-dropdown-userspace-show {
  width: 100%;
  position: absolute;
  top: 10vh;
  visibility: hidden;
  opacity: 0;
  /* animation: display-dropdown-userspace 1.5s forwards; */
}

@-webkit-keyframes display-dropdown-userspace {
  50% {
    top: 10vh;
  }

  100% {
    opacity: 1;
    transition: opacity 6s ease-in-out;
    visibility: visible;
    top: 17vh
  }
}

@keyframes display-dropdown-userspace {
  50% {
    top: 10vh;
  }

  100% {
    opacity: 1;
    transition: opacity 6s ease-in-out;
    visibility: visible;
    top: 17vh
  }
}

.sidebar-dropdown-userspace-collapse {
  width: 100%;
  position: absolute;
  top: 17vh;
  visibility: visible;
  opacity: 1;
  /* animation: collapse-dropdown-userspace 1s forwards; */
}

@-webkit-keyframes collapse-dropdown-userspace {
  25% {
    top: 17vh;
  }

  100% {
    opacity: 0;
    transition: opacity 4s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}

@keyframes collapse-dropdown-userspace {
  25% {
    top: 17vh;
  }

  100% {
    opacity: 0;
    transition: opacity 4s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}

.sidebar-dropdown-favourite-show {
  width: 100%;
  position: absolute;
  top: 10vh;
  visibility: hidden;
  opacity: 0;
  /* animation: display-dropdown-favourite 1.25s forwards; */
}

@-webkit-keyframes display-dropdown-favourite {
  40% {
    top: 10vh;
  }

  100% {
    opacity: 1;
    transition: opacity 5s ease-in-out;
    visibility: visible;
    top: 21vh
  }
}

@keyframes display-dropdown-favourite {
  40% {
    top: 10vh;
  }

  100% {
    opacity: 1;
    transition: opacity 5s ease-in-out;
    visibility: visible;
    top: 21vh
  }
}

.sidebar-dropdown-favourite-collapse {
  width: 100%;
  position: absolute;
  top: 21vh;
  visibility: visible;
  opacity: 1;
  /* animation: collapse-dropdown-favourite 1.25s forwards; */
}

@-webkit-keyframes collapse-dropdown-favourite {
  40% {
    top: 21vh;
  }

  100% {
    opacity: 0;
    transition: opacity 5s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}

@keyframes collapse-dropdown-favourite {
  40% {
    top: 21vh;
  }

  100% {
    opacity: 0;
    transition: opacity 5s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}

.sidebar-dropdown-template-add-show {
  width: 100%;
  position: absolute;
  top: 10vh;
  visibility: hidden;
  opacity: 0;
  /* animation: display-dropdown-template-add 1s forwards; */
}

@-webkit-keyframes display-dropdown-template-add {
  25% {
    top: 10vh;
  }

  100% {
    opacity: 1;
    transition: opacity 4s ease-in-out;
    visibility: visible;
    top: 25vh
  }
}

@keyframes display-dropdown-template-add {
  25% {
    top: 10vh;
  }

  100% {
    opacity: 1;
    transition: opacity 4s ease-in-out;
    visibility: visible;
    top: 25vh
  }
}

.sidebar-dropdown-template-add-collapse {
  width: 100%;
  position: absolute;
  top: 25vh;
  visibility: visible;
  opacity: 1;
  /* animation: collapse-dropdown-template-add 1.5s forwards; */
}

@-webkit-keyframes collapse-dropdown-template-add {
  50% {
    top: 25vh;
  }

  100% {
    opacity: 0;
    transition: opacity 6s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}

@keyframes collapse-dropdown-template-add {
  50% {
    top: 25vh;
  }

  100% {
    opacity: 0;
    transition: opacity 6s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}

.sidebar-dropdown-template-edit-show {
  width: 100%;
  position: absolute;
  top: 10vh;
  visibility: hidden;
  opacity: 0;
  /* animation: display-dropdown-template-edit 0.75s forwards; */
}

@-webkit-keyframes display-dropdown-template-edit {
  25% {
    top: 10vh;
  }

  100% {
    visibility: visible;
    opacity: 1;
    transition: opacity 3s ease-in-out;
    top: 29vh
  }
}

@keyframes display-dropdown-template-edit {
  25% {
    top: 10vh;
  }

  100% {
    visibility: visible;
    opacity: 1;
    transition: opacity 3s ease-in-out;
    top: 29vh
  }
}

.sidebar-dropdown-template-edit-collapse {
  width: 100%;
  position: absolute;
  top: 29vh;
  visibility: visible;
  opacity: 1;
  /* animation: collapse-dropdown-template-edit 1.75s forwards; */
}

@-webkit-keyframes collapse-dropdown-template-edit {
  60% {
    top: 29vh;
  }

  100% {
    opacity: 0;
    transition: opacity 7s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}

@keyframes collapse-dropdown-template-edit {
  60% {
    top: 29vh;
  }

  100% {
    opacity: 0;
    transition: opacity 7s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}
.dashboard-body {
  background-color: #f4f7ff;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
  color: black;
  overflow-x: hidden;
}

.dashboard-background {
  width: 100%;
}

.dashboard-outer {
  width: 100%;
  max-height: -webkit-fit-content;
  max-height: fit-content;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
}

.dashboard-inner {
  width: 90%;
  margin: 0 auto;
  margin-top: 2%;
}

.dashboard-title {
  margin: 0 auto;
  font-weight: bold;
}

.dashboard-main-area {
  display: flex;
  flex-direction: row;
}

.unclaimedTasksAnnounce {
  width: 60%;
  margin: 0 auto;
  background-color: #db8f84;
  border-radius: 10px;
  margin-bottom: 5%;
  padding: 1%;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1.2em;
}

.unclaimedTasksAnnounceTV {
  width: 60%;
  margin: 0 auto;
  background-color: #db8f84;
  border-radius: 10px;
  margin-bottom: 5%;
  padding: 1%;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1em;
}

.timesheetDatePicker {
  width: 13vw;
  max-width: 150px;
  margin: 0 auto;
  font-size: 0.8em;
  text-align: center;
  margin-bottom: 1%;
}

.timesheetDatePickerDates {
  width: 40%;
  margin: 0 auto;
  font-size: 0.8em;
  margin-bottom: 2%;
}

.unclaimedTasksAnnounceIcon {
  width: 5vw;
  max-width: 80px;
  margin: 0 auto;
  border-radius: 100%;
  padding: 1%;
  background-color: #a65044;
}

.dashboard-widgets-chart-area {
  width: 100%;
}

.doughnut-timesheets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}

.doughnut-timesheets-weekend {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 40%;
  margin: 0 auto;
  margin-top: 2%;
}

.timesheet-chart-weekend {
  width: 45%;
  box-shadow: 1px 1px 5px 3px grey;
  padding-bottom: 1%;
  font-size: 0.7em;
  font-family: "Montserrat", sans-serif;
  background-color: #fafafa;
}

.timesheet-chart-weekend:hover {
  cursor: pointer;
}

.timesheet-chart {
  width: 19%;
  box-shadow: 1px 1px 5px 3px grey;
  padding-bottom: 1%;
  font-size: 0.7em;
  font-family: "Montserrat", sans-serif;
  background-color: #fafafa;
}

.timesheet-chart:hover {
  cursor: pointer;
}

.timesheets-graph-header {
  width: 100%;
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2%;
}

.charts {
  width: 100%;
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
}

.chartjs-render-monitor {
  background-color: #fafafa;
}

.widget-body {
  display: flex;
  flex-direction: column;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  font-size: 0.8em;
  width: 12vw;
  max-width: 220px;
  /* border-radius: 3%;
  border: solid 3px black; */
  font-family: "Montserrat", sans-serif;
  color: black;
  font-weight: bold;
  background-color: white;
  box-shadow: 1px 1px 5px 3px grey;
}

.widget-body:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}

.widget-data {
  font-size: 2em;
}

.widget-title {
  font-size: 0.5em;
  margin-top: auto;
  color: #878787;
}

.widget-icon {
  width: 30%;
  max-width: 50px;
  margin: 0 auto;
  border-radius: 100%;
  padding: 3%;
}

.widget-icon>div {
  padding: 0%;
}

.dashboard-widgets {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 10%;
  padding-right: 10%;
}

.table-client-parent {
  width: 100%;
  margin: 0 auto;
  margin-left: 1%;
}

.table-client {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  background-color: white;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.table-header-client {
  background-color: #d5eef2;
  padding: 2%;
  font-size: 0.9em;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  /* border-bottom: solid 2px black; */
}

.table-column-headers-client {
  font-size: 0.6em;
  padding-left: 1%;
  padding-right: 1%;
}

.table-client-td {
  padding-left: 3.75%;
  padding-right: 3.75%;
}

.table-client-row>td {
  font-size: 0.5em;
  /* border: solid 2px grey; */
  padding-top: 0.75%;
  padding-bottom: 0.75%;
}

.timesheet-employee-name {
  width: 100%;
  margin: 0 auto;
}

.charts-new-tickets {
  width: 50%;
}

.TVUnclaimedTasksListContainer {
  width: 100%;
  margin-left: 25%;
  margin-bottom: 5%;
}

.TVUnclaimedTasksList {
  font-size: 0.5em;
  text-align: justify;
}

.critical-tickets-table {
  width: 80%;
  margin: 0 auto;
  margin-top: 2%;
  margin-bottom: 2%;
}

.tickets-by-timesheets-table {
  width: 80%;
  margin: 0 auto;
  margin-top: 2%;
  margin-bottom: 2%;
}

.tickets-by-timesheets-table>div>div>div>div>div>table>thead>tr>th:nth-child(6) {
  min-width: 40vw !important;
}

@media only screen and (max-width: 1600px) {
  .doughnut-timesheets {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  .timesheet-chart {
    width: 30%;
    margin-top: 2%;
  }
  .charts {
    width: 90%;
    align-items: center;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
  }
  .charts-new-tickets {
    width: 90%;
    margin-bottom: 5%;
  }
  .table-client-parent {
    width: 100%;
  }
  .table-client {
    width: 90% !important;
    margin: 0 auto;
  }
  .table-header-client {
    font-size: 1em;
  }
  .table-column-headers-client {
    font-size: 0.65em;
    padding-left: 0%;
    padding-right: 0%;
  }
  .table-client-td {
    padding-left: 0%;
    padding-right: 0%;
  }
  .table-client-row>td {
    font-size: 0.65em;
    padding-top: 0.75%;
    padding-bottom: 0.75%;
  }
  #root>div>header>div>div.dashboard-inner>div:nth-child(2) {
    width: 100%;
  }
  .tickets-by-timesheets-table {
    width: 80%;
    align-items: center;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 1270px) {
  .doughnut-timesheets {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-betweem;
  }
  .timesheet-chart {
    width: 45%;
    margin-top: 2%;
  }
}

@media only screen and (max-width: 978px) {
  .dashboard-widgets {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .widget-body {
    flex-direction: column;
    margin-bottom: 3%;
    font-size: 1.6em;
    width: 100%;
  }
  .dashboard-title {
    font-size: 2em;
    margin-bottom: 10%;
  }
  .unclaimedTasksAnnounce {
    width: 100%;
    font-size: 1em;
  }
  .unclaimedTasksAnnounceIcon {
    width: 10%;
  }
  .doughnut-timesheets {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
  }
  .timesheet-chart {
    width: 100%;
    margin-bottom: 5%;
    box-shadow: 1px 1px 5px 3px grey;
    padding-bottom: 1%;
    font-size: 1em;
    font-family: "Montserrat", sans-serif;
    background-color: #fafafa;
  }
  .timesheet-employee-name {
    font-size: 1.6em;
  }
  .timesheets-graph-header {
    font-size: 1.5em;
  }
  .timesheetDatePicker {
    width: 50%;
    font-size: 1.2em;
  }
  .timesheetDatePickerDates {
    width: 100%;
    font-size: 1em;
  }
  .widget-body {
    width: 50vw;
    max-width: 50vw;
  }
  .widget-icon {
    max-width: 20vw;
  }
  .tickets-by-timesheets-table {
    width: 90%;
  }
}
#secondary {
  background-color: #6c757d;
  color: white;
  font-size: 0.4;
}

#primary {
  background-color: #007bff;
  color: white;
  font-size: 0.4;
  margin-left: 2%;
}

.labelField {
  font-size: 1.3em;
}

.formField {
  font-size: 1em;
  margin-bottom: 5%;
}

.labelFieldSecondary {
  font-size: 1.3em;
}

.labelField:after {
  content: "*  ";
  color: red;
}

.labelDetails {
  padding-top: 2%;
  padding-bottom: 20%;
}

.modalTitle {
  text-align: center;
  align-self: center;
}

#deleteTicket {
  background-color: red;
  color: white;
  justify-content: start;
  margin-right: 10%;
}

#controlled-tab-example-tabpane-myob
  > div.modal-body
  > div
  > div
  > div.MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-74.MuiToolbar-gutters {
  min-height: 0px;
}

.tickets-edit-table
  > div
  > div
  > div
  > div
  > div
  > table
  > thead
  > tr
  > th:nth-child(4) {
  min-width: 1vw !important;
}

.tickets-edit-table
  > div
  > div
  > div
  > div
  > div
  > table
  > thead
  > tr
  > th:nth-child(6) {
  min-width: 20vw !important;
}

.tickets-edit-table
  > div
  > div
  > div
  > div
  > div
  > table
  > thead
  > tr
  > th:nth-child(7) {
  min-width: 15vw !important;
}

.addTime {
  text-align: center;
  width: 90%;
  margin-top: 5%;
}

.addTimeBtn {
  padding-left: 7% !important;
  padding-right: 7% !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
}

.refreshTimesheets {
  text-align: center;
  width: 100%;
  margin-bottom: 3%;
  margin-top: 5%;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.ticket-edit-row-customer-title {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-edit-row-assignee {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-edit-row-dates {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-edit-row-priority-status {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-edit-row-date-units-activity {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.editTicketModal {
  width: 60%;
  max-width: none !important;
}

@media only screen and (max-width: 978px) {
  .ticket-edit-row-customer-title {
    flex-direction: column;
  }

  .ticket-edit-row-assignee {
    flex-direction: column;
  }

  .ticket-edit-row-dates {
    flex-direction: column;
  }

  .ticket-edit-row-priority-status {
    flex-direction: column;
  }

  .ticket-edit-row-date-units-activity {
    flex-direction: column;
  }

  .editTicketModal {
    width: 95%;
  }
}
.view-log-btn {
  color: #56878f;
  font-weight: bolder;
  font-size: 1.1em;
  cursor: pointer;
  text-align: center;
}

.view-log-modal {

  align-items: center;
  justify-content: center;
}

.view-log-modal-open {
  width: 80% !important;
  max-height: 90vh !important;
  background-color: white;

  margin-left: 10%;
  text-align: center;
  font-weight: bold;
  padding: 0%;
  align-items: center;
}

.view-log-modal-open > div > div > div > div > div > table > tbody > tr > td:nth-child(2) {
    width: 70% !important;
}
.accounts-display-container {
  width: 100%;
  margin: 0 auto;
}

.accounts-contact-search-bar {
  margin: 0 auto;
  width: 70%;
  font-size: 1.5vw;
  font-weight: 400;
}

.accounts-contact-information {
  flex-direction: column;
  align-items: center;
  font-size: 0.6em;
  text-align: left;
  width: 100%;
}

.accounts-contact-view-details {
  padding-top: 6px;
  text-align: right;
}

.accounts-contact-view-details-info {
  text-align: center;
}

.phone-button {
  max-width: 3%;
  height: auto;
}

.phone-button:hover {
  max-width: 4%;
  height: auto;
  cursor: pointer;
}

#secondary {
  background-color: #6c757d;
  color: white;
  font-size: 0.4;
}

#primary {
  background-color: #007bff;
  color: white;
  font-size: 0.4;
  margin-left: 2%;
}

.newTicketModal {
  width: 60%;
  max-width: none !important;
}

.labelField {
  font-size: 1.3em;
}

.formField {
  font-size: 1em;
  margin-bottom: 5%;
}

.labelFieldSecondary {
  font-size: 1.3em;
}

.labelField:after {
  content: "*  ";
  color: red;
}

.labelDetails {
  padding-top: 2%;
  padding-bottom: 20%;
}

.datePicker {
  /* width: 100%; */
  padding-bottom: 2%;
  font-size: 1.3em;
  border-color: #ced4da;
  border-radius: 5px;
  border-width: 1.3px;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.ticketBtns {
  width: 90%;
  display: flex;
  flex-direction: row;
}

.refreshTickets {
  margin-left: 1%;
}

.ticket-create-select-template {
  width: 50%;
  margin-bottom: 1%;
}

.ticket-create-row-customer-title {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-create-row-assignee {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-create-row-dates {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-create-row-priority-status {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-create-row-date-units-activity{
  width: 100%;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 978px) {
  .labelField {
    width: 100%;
  }

  .formField {
    width: 100%;
  }

  .ticket-create-select-template {
    width: 100%;
    margin-bottom: 1%;
  }

  .ticket-create-row-customer-title {
    flex-direction: column;
  }

  .ticket-create-row-assignee {
    flex-direction: column;
  }

  .ticket-create-row-dates {
    flex-direction: column;
  }

  .ticket-create-row-priority-status {
    flex-direction: column;
  }

  .ticket-create-row-date-units-activity{
    flex-direction: column;
  }

  .newTicketModal {
    width: 95%;
  }
}
.view-account-contacts-btn {
    cursor: pointer;
  }
  
  .view-account-contacts-modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .view-account-contacts-modal-open {
    width: 40%;
    max-height: 90vh !important;
    background-color: white;
    border: 2px solid #000;
    margin-left: 10%;
    text-align: center;
    font-weight: bold;
    padding: 1%;
    overflow-y: scroll;
  }

  .account-contacts-name {
    color: #56878f;
  }
.tickets {
  width: 95%;
  margin: 0 auto;
}

.ticketTimesTable {
  width: 95%;
  margin: 0 auto;
}

.MTableBodyRow-root {
  background-color: black;
}

.tableHeader {
  margin: 0 auto;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 2.5%;
  font-weight: bold;
}

.newTicket {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 1%;
  margin-left: 3%;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.completedTicketsHide {
  display: flex;
  font-size: 0.8em;
  justify-content: flex-start;
  margin-left: 5%;
  border-style: solid !important;
  border: 5px !important;
  border-color: black;
}

.centerTabs {
  display: 'flex';
  justify-content: 'center';
  width: 700px;
}

.datePicker {
  padding-top: 10px;
  padding-left: 10px;
  font-size: xx-large;
}

.loading-overlay {
  z-index: 999;
  /* Ensure it's on top of everything */
  color: #fff;
  /* Color for the spinner */
}

.tickets>div>div>div>div>div>table>thead>tr>th:nth-child(1) {
  min-width: 1vw !important;
}

.tickets>div>div>div>div>div>table>thead>tr>th:nth-child(2) {
  min-width: 30vw !important;
}

.tickets>div>div>div>div>div>table>thead>tr>th:nth-child(3) {
  min-width: 15vw !important;
}

.ticketTimesTable>div>div>div>div>div>table>thead>tr>th:nth-child(7) {
  min-width: 30vw !important;
}

@media only screen and (max-width: 1499px) {
  .tickets {
    width: 90%;
  }
}

@media only screen and (max-width: 1377px) {
  .tickets {
    width: 90% !important;
  }

  .newTicket {
    margin-left: 5%;
  }
}
.admin-body {
    background-color: rgba(255, 255, 255, 0.973);
    text-align: center;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1em;
    color: black;
    overflow-x:hidden;
  }

.testHeader {
  width: 100%;
  max-height: -webkit-fit-content;
  max-height: fit-content;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
}

.timesheetSelectorHeader {
  margin: 0 auto;
  padding-top: 1%;
  padding-bottom: 1%;
  font-weight: bold;
  margin-bottom: 2%;
  margin-top: 5%;
}

.exportPage {
  width: 100% !important;
  flex-direction: column !important
}

@media only screen and (max-width: 978px) {
  .mobile {
    width: 100% !important;
  }

  #root > div > header > div > div.testHeader > div:nth-child(2) {
    width: 100%;
  }
}
.timesheet-export-container {
  width: 95%;
  margin: 0 auto;
}

.MTableBodyRow-root {
  background-color: black;
}

.tableHeader {
  margin: 0 auto;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 2.5%;
}

.newTicket {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 1%;
}

.dlCSV {
  margin-bottom: 2%;
  width: 10% !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
}

.dlCSVNone {
  display: none;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.timesheet-export-container > div > div > div > div > div > table > thead > tr > th:nth-child(12) {
  min-width: 30vw !important;
}
.accounts-update-new-btn {
    width: 12%;
    display: inline-block;
    font-size: 0.6em;
    margin: 1%;
    padding: 0.5%;
    border: 3px solid black;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .accounts-update-form {
    width: 30%;
    margin: 0 auto;
    background-color: #3b6db3;
    border-radius: 3%;
    padding: 1.5%;
  }
  
  .accounts-update-label {
    width: 100%;
    font-size: 0.6em;
    color: white;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
  }
  
  .accounts-update-label-required {
    width: 100%;
    font-size: 0.6em;
    color: white;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
  }
  
  .accounts-update-label-required:after {
    content: "*  ";
    color: red;
  }
  
  .accounts-update-field {
      width: 100%;
      font-size: 0.8em;
      border: solid 0px;
      border-radius: 8px;
  }
.accounts-display-container {
    width: 100%;
    margin: 0 auto;
  }
  
  .accounts-contact-search-bar {
    margin: 0 auto;
    width: 70%;
    font-size: 1.5vw;
    font-weight: 400;
  }
  
  .accounts-contact-information {
    flex-direction: column;
    align-items: center;
    font-size: 0.6em;
    text-align: left;
    width: 100%;
  }
  
  .accounts-contact-view-details {
    padding-top: 8px;
    text-align: right;
  }
  
  .accounts-contact-view-details-info {
    text-align: center;
    width: 50%;
  }
  
  .phone-button {
    max-width: 20px;
    height: auto;
  }
  
  .phone-button:hover {
    max-width: 22px;
    height: auto;
    cursor: pointer;
  }

  .accounts-contact-view-details-info textarea {
    width: 90%;
    height: 100px;
  }
  
.people-add-new-btn {
    width: 12%;
    display: inline-block;
    font-size: 0.6em;
    margin: 1%;
    padding: 0.5%;
    border: 3px solid black;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .people-add-form {
    width: 55%;
    margin: 0 auto;
    background-color: #3b6db3;
    border-radius: 3%;
    padding: 1.5%;
  }
  
  .people-add-label {
    width: 100%;
    font-size: 0.6em;
    color: white;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
  }
  
  .people-add-label-required {
    width: 100%;
    font-size: 0.6em;
    color: white;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
  }
  
  .people-add-label-required:after {
    content: "*  ";
    color: red;
  }
  
  .people-add-field {
      width: 100%;
      font-size: 0.8em;
      border: solid 0px;
      border-radius: 8px;
  }

.people-add-row {
  display: flex;
  justify-content: space-between;
}

.people-add-row-name {
  width: 48% !important;
}

.people-add-ph-notes {
  display: flex;
}

.people-add-col-phone {
  width: 100%;
  margin-left: 0%;
  padding-top: 1%;
}

.people-add-col-notes {
  width: 100%;
  margin-left: 3%;
  padding-top: 1%;
}

.people-add-row > div {
  width: 32%;
}
.people-update-btn {
  color: #56878f;
  font-weight: bolder;
  font-size: 1.1em;
  cursor: pointer;
}

.people-update-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.people-update-modal-open {
  width: 45%;
  max-height: 90vh !important;
  background-color: white;
  border: 2px solid #000;
  margin-left: 10%;
  text-align: center;
  font-weight: bold;
  padding: 1%;
}

.people-update-new-btn {
  width: 12%;
  display: inline-block;
  font-size: 1em;
  margin: 1%;
  padding: 0.5%;
  border: 3px solid black;
  border-radius: 10px;
  cursor: pointer;
}

.people-update-form {
  width: 100%;
  margin: 0 auto;
  background-color: #3b6db3;
  border-radius: 3%;
  padding: 1.5%;
}

.people-update-label {
  width: 100%;
  font-size: 1em;
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  padding-top: 1%;
}

.people-update-label-required {
  width: 100%;
  font-size: 1em;
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  padding-top: 1%;
}

.people-update-label-required:after {
  content: "*  ";
  color: red;
}

.people-update-field {
  width: 100%;
  font-size: 1em;
  border: solid 0px;
  border-radius: 8px;
}

.people-update-row {
  display: flex;
  justify-content: space-between;
  padding-top: 1%;
}

.people-update-ph-notes {
  display: flex;
}

.people-update-col-phone {
  justify-content: left;
  padding-top: 1%;
  width: 100%;
}

.people-update-col-notes {
  width: 100%;
  margin-left: 3%;
  padding-top: 1%;
}

.people-update-row-name {
  width: 48% !important;
}

.notes {
  width: 100%;
  height: 13vh;
}

.people-itglue-export-btn {
  margin-top: 3%;
  margin-bottom: 3%;
}

.mail-logo {
  max-width: 20px;
  height: auto;
}

.mail-logo:hover {
  max-width: 22px;
  height: auto;
  cursor: pointer;
}
.accounts-management-container {
  width: 100%;
}

.accounts-management-title {
  font-size: 1.2em;
  font-weight: bold;
}

.accounts-management-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.accounts-option-btn {
  width: 15%;
  font-size: 0.5em;
  margin: 2%;
  padding: 1%;
  border: 3px solid black;
  border-radius: 10px;
  cursor: pointer;
}

.accounts-display-container {
  width: 100%;
  margin: 0 auto;
}

.accounts-display-table {
  width: 50%;
  font-size: 0.6em;
  margin: 0 auto;
  margin-top: 5%;
}

.accounts-filter-by-letter {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  width: 70%;
  margin: 0 auto !important;
  text-align: center !important;
  color: #56878f;
  font-size: 1.5vw;
  font-weight: 400;
}

.accounts-filter-by-letter > div {
  width: 5%;
}

.accounts-filter-by-letter > div:hover {
  cursor: pointer;
}

.accounts-contact-search-bar {
  margin: 0 auto;
  width: 70%;
  font-size: 1.5vw;
  font-weight: 400;
}

.accounts-contact-information {
  
  flex-direction: column;
  align-items: center;
  font-size: 0.6em;
  text-align: left;
  width: 100%;
}
.accounts-update-new-btn {
  width: 12%;
  display: inline-block;
  font-size: 0.6em;
  margin: 1%;
  padding: 0.5%;
  border: 3px solid black;
  border-radius: 10px;
  cursor: pointer;
}

.accounts-contact-view-details {
  text-align: right;
}

.accounts-contact-view-details-info {
  text-align: center;
}
.accounts-add-new-btn {
  width: 12%;
  display: inline-block;
  font-size: 0.6em;
  margin: 1%;
  padding: 0.5%;
  border: 3px solid black;
  border-radius: 10px;
  cursor: pointer;
}

.accounts-add-form {
  width: 30%;
  margin: 0 auto;
  background-color: #3b6db3;
  border-radius: 3%;
  padding: 1.5%;
}

.accounts-add-label {
  width: 100%;
  font-size: 0.6em;
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.accounts-add-label-required {
  width: 100%;
  font-size: 0.6em;
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.accounts-add-label-required:after {
  content: "*  ";
  color: red;
}

.accounts-add-field {
    width: 100%;
    font-size: 0.8em;
    border: solid 0px;
    border-radius: 8px;
}
.admin-body {
  background-color: rgba(255, 255, 255, 0.973);
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
  color: black;
  overflow-x: hidden;
}

.customersTable {
  width: 95%;
  margin: 0 auto;
}

.customersHeader {
  margin: 0 auto;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 1%;
  font-weight: bold;
  margin-bottom: 2%;
}

.notes {
  font-size: 0.6em;
}

.notesContact {
  font-size: 0.6em;
  padding-bottom: 3%;
}

.accountDropDownHeader {
  margin: 0 auto;
  padding-top: 1%;
  padding-bottom: 1%;
  font-weight: bold;
  margin-bottom: 5%;
  margin-top: 5%;
}

.LocationPeopleHeader {
  margin: 0 auto;
  padding-top: 1%;
  padding-bottom: 1%;
  font-weight: bold;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.testHeader {
  width: 100%;
  max-height: -webkit-fit-content;
  max-height: fit-content;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
}

.accountList {
  margin: 0 auto;
  width: 100%;
}

.accountDropDown {
  width: 50%;
  margin: 0 auto;
}

.dlCSV {
  background-color: #004fcf;
}

.dlCSVYeastar {
  background-color: #004fcf !important;
  max-width: 30% !important;
}

.accounts-yeastar-export {
  margin-top: 3%;
}

@media only screen and (max-width: 1499px) {
  .customersTable {
    width: 90%;
  }
}

@media only screen and (max-width: 1377px) {
  .customersTable {
    width: 80%;
  }

  .accounts-yeastar-export {
    display: none;
  }
}

@media only screen and (max-width: 978px) {
  .customersTable {
    width: 95%;
  }

  .accountList {
    width: 100%;
  }

  .dlCSV {
    width: 20% !important;
  }

  .accounts-yeastar-export {
    display: none;
  }
}

.tickets-unique {
  width: 95%;
  margin: 0 auto;
}

.MTableBodyRow-root {
  background-color: black;
}

.newTicket {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 1%;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.completedTicketsHide {
  display: flex;
  font-size: 0.8em;
  justify-content: flex-start;
  margin-left: 5%;
  border-style: solid !important;
  border: 5px !important;
  border-color: black;
}

.tickets-unique > div > div > div > div > div > table > thead > tr > th:nth-child(1) {
  min-width: 3vw !important;
}

.tickets-unique > div > div > div > div > div > table > thead > tr > th:nth-child(2) {
  min-width: 30vw !important;
}

.tickets-unique > div > div > div > div > div > table > thead > tr > th:nth-child(3) {
  min-width: 15vw !important;
}

@media only screen and (max-width: 1499px) {
  .tickets-unique {
    width: 90%;
  }
}

@media only screen and (max-width: 1377px) {
  .tickets-unique {
    width: 90% !important;
  }
  .newTicket {
    margin-left: 5%;
  }
}
.tasks {
  width: 95%;
  margin: 0 auto;
}

.viewDetails {
  color: #56878f;
  font-weight: bolder;
  font-size: 1.1em;
  cursor: pointer;
}

.MTableBodyRow-root {
  background-color: black;
}

.tableHeader {
  margin: 0 auto;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 2.5%;
  font-weight: bold;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.tabsTasks {
  width: 100%;
  margin-bottom: 3%;
}

/* #controlled-tab-example-tab-inProgress {
  background-color: #ffffc7;
  color: black;
}

#controlled-tab-example-tab-completed {
  background-color: #c7ffcb;
  color: black;
} */

div.tabsTasks > nav {
  margin: 0 auto;
  justify-content: center;
  font-size: 0.7em;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

div.tabsTasks > nav > .nav-item {
  background-color: #3b6db3;
  color: white;
  padding: 2%;
  margin: 0.3%;
}

div.tabsTasks > nav > .nav-link.active {
  background-color: white;
  color: black;
  border: 3px black solid;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-ignore-onclickoutside {
  width: 100%;
}

.react-datepicker__input-container > input {
  width: 100%;
}

.detailsHeader {
  font-weight: bold;
  font-size: 1.2em;
  width: 100%;
}

.detailsBtns {
  display: flex;
  margin-top: 10%;
}

.tasks-btn {
  width: 20%;
  background-color: #009400;
  color: white;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  padding: 1%;
  cursor: pointer;
  font-weight: bold;
}

.taskAddForm {
  width: 30%;
  margin: 0 auto;
  background-color: #3b6db3;
  border-radius: 3%;
  padding: 3%;
}

.taskLabelField {
  width: 100%;
  font-size: 0.6em;
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.taskLabelField:after {
  content: "*  ";
  color: red;
}

.taskAddSubmit {
  width: 7.5%;
  margin: 0 auto;
  padding: 0.5% 0.25%;
  margin-top: 1%;
  margin-bottom: 5%;
  font-size: 0.7em;
  cursor: pointer;
  background-color: #3f51b5;
  color: white;
  font-weight: bold;
  border-radius: 3px;
}

.taskAddSubmit:hover {
  background-color: #374594;
}

@media only screen and (max-width: 1499px) {
  .tickets {
    width: 90%;
  }
}

@media only screen and (max-width: 1377px) {
  .tickets {
    width: 90% !important;
  }

  .newTicket {
    margin-left: 5%;
  }
}

@media only screen and (max-width: 978px) {
  .taskAddForm {
    width: 90%;
  }

  .taskLabelField {
    font-size: 0.9em;
  }

  .taskAddSubmit {
    width: 50%;
    padding: 5%;
  }

  .tasks-btn {
    width: 40%;
    padding: 5%;
  }
}
.view-task-details-btn {
  cursor: pointer;
}

.view-task-details-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-task-details-modal-open {
  width: 40%;
  max-height: 90vh !important;
  background-color: white;
  border: 2px solid #000;
  margin-left: 10%;
  text-align: center;
  font-weight: bold;
  padding: 1%;
  overflow-y: scroll;
}

.task-details-btn {
    /* display: flex; */
}

.task-details-account {
    color: #56878f;
    font-size: 0.5em;
    font-weight: bold;
}

.task-details-title {
    font-size: 0.5em;
}
.admin-body {
  background-color: rgba(255, 255, 255, 0.973);
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
  color: black;
}

.userspace-body-internal {
  width: 100%;
  max-height: -webkit-fit-content;
  max-height: fit-content;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
}

.userspace-content {
  width: 100%;
  display: column !important;
}

.page-header {
  margin: 0 auto;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 2.5%;
  font-weight: bold;
}

.task-details-container {
  width: 33%;
  line-height: 1;
}

.tasks-user {
  width: 90%;
  margin: 0 auto;
  display: flex;
  text-align: left;
  margin-bottom: 2%;
  font-size: 1em
}

.tasks-account-name {
  font-weight: bold !important;
}

.task-account-title {
  font-size: 0.5em;
}

.task-title-unique {
  text-align: center;
  font-weight: bold;
}

@media only screen and (max-width: 1270px) {
  .tasks-user {
    flex-direction: column;
    align-items: center;
    margin-right: 10%;
  }
  .task-details-container {
    width: 90%;
    text-align: center;
    font-size: 1.4em;
  }
}
/* .ws-doc {
    width: 60%;
    margin: 0 auto;
} */

.ws-doc-btn-container {
    width: 5vw;
    align-self: center !important;
}

.ws-doc-btn-container :hover {
    cursor: pointer;
}

.ws-doc-btn {
    color: white;
}

.ws-doc-default {
    display: flex;
    position: fixed;
    background-color: #4f7822;
    top: 10%;
    right: -46%;
    z-index: 997;
    width: 50vw !important;
    border-radius: 2%;
    font-size: 1.2em;
    border: solid 3px black;
    border-radius: 10px;
    margin-right: 1%;
}

.ws-doc-hiding {
    display: flex;
    position: fixed;
    background-color: #4f7822;
    top: 10%;
    right: -46%;
    z-index: 997;
    width: 50vw !important;
    border-radius: 2%;
    font-size: 1.2em;
    border: solid 3px black;
    border-radius: 10px;
    margin-right: 1%;
    -webkit-animation: display-doc-hide 2s backwards;
            animation: display-doc-hide 2s backwards;
}

@-webkit-keyframes display-doc-hide {
    0% {
        right: 0%;
    }
    100% {
        right: -46%;
    }
}

@keyframes display-doc-hide {
    0% {
        right: 0%;
    }
    100% {
        right: -46%;
    }
}

.ws-doc {
    display: flex;
    position: fixed;
    background-color: #4f7822;
    top: 10%;
    right: -46%;
    z-index: 997;
    width: 50vw !important;
    border-radius: 2%;
    font-size: 1.2em;
    border: solid 3px black;
    border-radius: 10px;
    margin-right: 1%;
    -webkit-animation: display-doc 2s forwards;
            animation: display-doc 2s forwards;
}

@-webkit-keyframes display-doc {
    0% {
        right: -46%;
    }
    100% {
        right: 0%;
    }
}

@keyframes display-doc {
    0% {
        right: -46%;
    }
    100% {
        right: 0%;
    }
}

.ws-doc-inner {
    width: 100%;
}

.ws-doc-tab-bar {
    align-items: center !important;
    background-color: white !important;
    color: black !important;
    border-bottom: solid black 3px !important;
}

.ws-doc-tab-bar:active {
    border-bottom: solid black 3px !important;
}

div.ws-doc-inner>header>div>div>span {
    background-color: black;
    font-weight: bold;
}

@media only screen and (max-width: 1600px) {
    .ws-doc-default {
        display: none
    }
    .ws-doc {
        display: none
    }
    .ws-doc-hiding {
        display: none
    }
}
.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 60px;
    height: 35px;
    background: grey;
    border-radius: 60px;
    position: relative;
    transition: background-color .2s;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }
.admin-body {
    background-color: rgba(255, 255, 255, 0.973);
    text-align: center;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1em;
    color: black;
    overflow-x:hidden;
  }

.testHeader {
  width: 100%;
  max-height: -webkit-fit-content;
  max-height: fit-content;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
}

.timesheetSelectorHeader {
  margin: 0 auto;
  padding-top: 1%;
  padding-bottom: 1%;
  font-weight: bold;
  margin-bottom: 2%;
  margin-top: 5%;
}

.exportPage {
  width: 100% !important;
  flex-direction: column !important
}

@media only screen and (max-width: 978px) {
  .mobile {
    width: 100% !important;
  }

  #root > div > header > div > div.testHeader > div:nth-child(2) {
    width: 100%;
  }
}
.delivery-records-table {
  width: 95%;
  margin: 0 auto;
}

.viewDetails {
  color: #56878f;
  font-weight: bolder;
  font-size: 1.1em;
  cursor: pointer;
}

.MTableBodyRow-root {
  background-color: black;
}

.tableHeader {
  margin: 0 auto;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 2.5%;
  font-weight: bold;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.exportBtn > a {
  width: 100% !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-ignore-onclickoutside {
  width: 100%;
}

.react-datepicker__input-container > input {
  width: 100%;
}

.detailsHeader {
  font-weight: bold;
  font-size: 1.2em;
  width: 100%;
}

.detailsBtns {
  display: flex;
  margin-top: 10%;
}

.delivery-record-btn {
  width: 20%;
  background-color: #009400;
  color: white;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  padding: 1%;
  cursor: pointer;
  font-weight: bold;
}

.delivery-record-label-field, .delivery-record-label {
  width: 100%;
  font-size: 0.6em;
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.delivery-record-label-field:after {
  content: "*  ";
  color: red;
}

.modifyTaskBtn {
  width: 20%;
  background-color: #0e6b00;
  color: white;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  padding: 1%;
  cursor: pointer;
  font-weight: bold;
}

.editDeliveryForm {
  margin-bottom: 5%;
}

.addDeliveryRecord {
  width: 30%;
  margin: 0 auto;
  background-color: #3b6db3;
  border-radius: 3%;
  padding: 3%;
}

.recordAddSubmit {
  width: 7.5%;
  margin: 0 auto;
  padding: 0.5% 0.25%;
  margin-top: 1%;
  margin-bottom: 5%;
  font-size: 0.7em;
  cursor: pointer;
  background-color: #3f51b5;
  color: white;
  font-weight: bold;
  border-radius: 3px;
}

.recordAddSubmit:hover {
  background-color: #374594;
}

@media only screen and (max-width: 1499px) {
  .tickets {
    width: 90%;
  }
}

@media only screen and (max-width: 1377px) {
  .tickets {
    width: 90% !important;
  }

  .newTicket {
    margin-left: 5%;
  }
}

@media only screen and (max-width: 1377px) {
  .addDeliveryRecord {
    width: 90%;
  }

  .recordAddSubmit {
    width: 50%;
    padding: 5%;
  }

  .delivery-records-export-btn > a {
    width: 50% !important;
  }

  .delivery-record-btn {
    width: 40%;
    padding: 5%;
  }

  .delivery-record-label-field {
    font-size: 0.9em;
  }
}

.admin-body {
  background-color: rgba(255, 255, 255, 0.973);
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
  color: black;
  overflow-x: hidden;
}

.testHeader {
  width: 100%;
  max-height: -webkit-fit-content;
  max-height: fit-content;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
}

.timesheetSelectorHeader {
  margin: 0 auto;
  padding-top: 1%;
  padding-bottom: 1%;
  font-weight: bold;
  margin-bottom: 2%;
  margin-top: 5%;
}

.exportPage {
  width: 100% !important;
  flex-direction: column !important;
}

@media only screen and (max-width: 978px) {
  .mobile {
    width: 100% !important;
  }

  #root > div > header > div > div.testHeader > div:nth-child(2) {
    width: 100%;
  }
}

.leave_body {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.leave_body h1 {
  margin-top: 30px;
  font-size: 50px;
}

.leave_body h2 {
  margin-top: 30px;
  text-align: left;
}

.leave_btnContainer {
  width: 100%;
}

.leave_body th,
.leave_body td {
  font-size: 20px !important;
  max-width: 30vh;
  vertical-align: middle !important;
}

.leave_btnContainer Button {
  margin: 3vh 3vh;
  width: 20vh;
  height: 60px;
  background-color: #3f51b5;
  color: white;
}

.leave_body Table Button,
.view-account-contacts-modal Form Button {
  margin: 10px 10px;
  width: 7vw;
  height: 35px;
  background-color: #3f51b5;
  color: white;
}

.leave_body Table Button :hover,
.view-account-contacts-modal Form Button :hover {
  margin: 10px 10px;
  width: 7vh;
  height: 35px;
  opacity: 0.5;
  color: white;
}

.view-account-contacts-modal-open Form {
  width: 25vw;
  text-align: left;
  margin: auto;
}

.view-account-contacts-modal-open {
  align-content: center;
}

.leave_dateForm {
  width: 15vw !important;
  display: inline-table;
  padding-left: 1vh;
  padding-right: 1vh;
  text-align: left;
  font-size: large;
}
/* .container {
    position: fixed;
    top: 9%;
    left: 0px;
    z-index: 999;
    height: 20%;
    max-width: 300px;
    margin-left: 48.5%;
    border-radius: 2%;
    background: rgba(0, 0, 0, 0.2);
    background-color: #fafafa;
    font-size: 0.7em;
  } */

  .container-error {
    position: fixed;
    top: 3%;
    left: 0px;
    z-index: 999;
    /* height: 20vh !important; */
    width: 20vw !important;
    margin-left: 40vw;
    border-radius: 2%;
    /* background: rgba(0, 0, 0, 0.2); */
    background-color: #fafafa;
    font-size: 1.3vw;
    padding-bottom: 3%;
    font-weight: bold;
    border: solid 3px black;
    border-radius: 10px;
  }
  
  .hidden {
      display: none;
  }
  
  .errorBackground {
      align-items: center;
  }

.error__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #ff7a7a;
    fill: none;
    -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .error {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #ff7a7a;
    -webkit-animation: fillError .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
            animation: fillError .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  
  .error__check {
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @-webkit-keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @-webkit-keyframes scale {
    0%, 100% {
      -webkit-transform: none;
              transform: none;
    }
    50% {
      -webkit-transform: scale3d(1.1, 1.1, 1);
              transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes scale {
    0%, 100% {
      -webkit-transform: none;
              transform: none;
    }
    50% {
      -webkit-transform: scale3d(1.1, 1.1, 1);
              transform: scale3d(1.1, 1.1, 1);
    }
  }
  @-webkit-keyframes fillError {
    100% {
      box-shadow: inset 0px 0px 0px 30px #ff7a7a;
    }
  }
  @keyframes fillError {
    100% {
      box-shadow: inset 0px 0px 0px 30px #ff7a7a;
    }
  }
/* .container {
  position: fixed;
  top: 9%;
  left: 0px;
  z-index: 999;
  height: 20%;
  max-width: 300px;
  margin-left: 48.5%;
  border-radius: 2%;
  background: rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
} */

.container-success {
  position: fixed;
  top: 3%;
  left: 0px;
  z-index: 999;
  /* height: 20vh !important; */
  width: 20vw !important;
  margin-left: 43vw;
  border-radius: 2%;
  /* background: rgba(0, 0, 0, 0.2); */
  background-color: #fafafa;
  font-size: 1.3vw;
  padding-bottom: 3%;
  font-weight: bold;
  border: solid 3px black;
  border-radius: 10px;
}

.hidden {
    display: none;
}

.checkboxBackground {
    align-items: center;
}

.successCircle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .success {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
            animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  
  .successShape {
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @-webkit-keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @-webkit-keyframes scale {
    0%, 100% {
      -webkit-transform: none;
              transform: none;
    }
    50% {
      -webkit-transform: scale3d(1.1, 1.1, 1);
              transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes scale {
    0%, 100% {
      -webkit-transform: none;
              transform: none;
    }
    50% {
      -webkit-transform: scale3d(1.1, 1.1, 1);
              transform: scale3d(1.1, 1.1, 1);
    }
  }
  @-webkit-keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
  }

  @media only screen and (max-width: 978px) {
    .container {
        margin-left: 35%;
    }
  }

  @media only screen and (max-width: 420px) {
    .container {
        margin-left: 11%;
    }
  }
.container {
    position: fixed;
    top: 9%;
    left: 0px;
    z-index: 999;
    height: 20%;
    max-width: 300px;
    margin-left: 48.5vw;
    border-radius: 2%;
    /* background: rgba(0, 0, 0, 0.2); */
    background-color: #fafafa;
    -webkit-animation: fadein 2s;
            animation: fadein 2s;
    font-size: 1.3vw;
    font-weight: bold;
    border: solid 3px black;
    border-radius: 10px;
  }

  .hidden {
    display: none;
}
  

  @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
  

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media only screen and (max-width: 978px) {
    .container {
        margin-left: 35%;
    }
  }

  @media only screen and (max-width: 420px) {
    .container {
        margin-left: 11%;
    }
  }
.ws-notification {
  display: block;
  position: fixed;
  top: 6%;
  right: -40%;
  z-index: 998;
  width: 15vw !important;
  border-radius: 2%;
  font-size: 1.2em;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  border: solid 3px black;
  border-radius: 10px;
  color: black !important;
  margin-right: 1%;
  background-color: white;
  -webkit-animation: display-notification 8s forwards;
          animation: display-notification 8s forwards;
}

.ws-notification-success {
    display: block;
    position: fixed;
    top: 6%;
    right: -40%;
    z-index: 998;
    width: 15vw !important;
    border-radius: 2%;
    font-size: 1.2em;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    border: solid 3px black;
    border-radius: 10px;
    margin-right: 1%;
    background-color: rgb(49, 156, 49);
    -webkit-animation: display-notification 8s forwards;
            animation: display-notification 8s forwards;
}

.ws-notification-hide {
    display: none;
}

.ws-notification-message {
  color: white;
  text-align: center;
}

@-webkit-keyframes display-notification {
    20% { right: 0; }
    80% { right: 0; }
    100% { right: -40%; }
}

@keyframes display-notification {
    20% { right: 0; }
    80% { right: 0; }
    100% { right: -40%; }
}
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

