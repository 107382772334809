.admin-body {
  background-color: rgba(255, 255, 255, 0.973);
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
  color: black;
  overflow-x: hidden;
}

.customersTable {
  width: 95%;
  margin: 0 auto;
}

.customersHeader {
  margin: 0 auto;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 1%;
  font-weight: bold;
  margin-bottom: 2%;
}

.notes {
  font-size: 0.6em;
}

.notesContact {
  font-size: 0.6em;
  padding-bottom: 3%;
}

.accountDropDownHeader {
  margin: 0 auto;
  padding-top: 1%;
  padding-bottom: 1%;
  font-weight: bold;
  margin-bottom: 5%;
  margin-top: 5%;
}

.LocationPeopleHeader {
  margin: 0 auto;
  padding-top: 1%;
  padding-bottom: 1%;
  font-weight: bold;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.testHeader {
  width: 100%;
  max-height: fit-content;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
}

.accountList {
  margin: 0 auto;
  width: 100%;
}

.accountDropDown {
  width: 50%;
  margin: 0 auto;
}

.dlCSV {
  background-color: #004fcf;
}

.dlCSVYeastar {
  background-color: #004fcf !important;
  max-width: 30% !important;
}

.accounts-yeastar-export {
  margin-top: 3%;
}

@media only screen and (max-width: 1499px) {
  .customersTable {
    width: 90%;
  }
}

@media only screen and (max-width: 1377px) {
  .customersTable {
    width: 80%;
  }

  .accounts-yeastar-export {
    display: none;
  }
}

@media only screen and (max-width: 978px) {
  .customersTable {
    width: 95%;
  }

  .accountList {
    width: 100%;
  }

  .dlCSV {
    width: 20% !important;
  }

  .accounts-yeastar-export {
    display: none;
  }
}
