.admin-body {
  background-color: rgba(255, 255, 255, 0.973);
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
  color: black;
  overflow-x: hidden;
}

.testHeader {
  width: 100%;
  max-height: fit-content;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
}

.timesheetSelectorHeader {
  margin: 0 auto;
  padding-top: 1%;
  padding-bottom: 1%;
  font-weight: bold;
  margin-bottom: 2%;
  margin-top: 5%;
}

.exportPage {
  width: 100% !important;
  flex-direction: column !important;
}

@media only screen and (max-width: 978px) {
  .mobile {
    width: 100% !important;
  }

  #root > div > header > div > div.testHeader > div:nth-child(2) {
    width: 100%;
  }
}
