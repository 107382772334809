.container {
    position: fixed;
    top: 9%;
    left: 0px;
    z-index: 999;
    height: 20%;
    max-width: 300px;
    margin-left: 48.5vw;
    border-radius: 2%;
    /* background: rgba(0, 0, 0, 0.2); */
    background-color: #fafafa;
    animation: fadein 2s;
    font-size: 1.3vw;
    font-weight: bold;
    border: solid 3px black;
    border-radius: 10px;
  }

  .hidden {
    display: none;
}
  

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media only screen and (max-width: 978px) {
    .container {
        margin-left: 35%;
    }
  }

  @media only screen and (max-width: 420px) {
    .container {
        margin-left: 11%;
    }
  }