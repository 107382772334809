.accounts-display-container {
  width: 100%;
  margin: 0 auto;
}

.accounts-contact-search-bar {
  margin: 0 auto;
  width: 70%;
  font-size: 1.5vw;
  font-weight: 400;
}

.accounts-contact-information {
  flex-direction: column;
  align-items: center;
  font-size: 0.6em;
  text-align: left;
  width: 100%;
}

.accounts-contact-view-details {
  padding-top: 6px;
  text-align: right;
}

.accounts-contact-view-details-info {
  text-align: center;
}

.phone-button {
  max-width: 3%;
  height: auto;
}

.phone-button:hover {
  max-width: 4%;
  height: auto;
  cursor: pointer;
}
