/* .container {
  position: fixed;
  top: 9%;
  left: 0px;
  z-index: 999;
  height: 20%;
  max-width: 300px;
  margin-left: 48.5%;
  border-radius: 2%;
  background: rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
} */

.container-success {
  position: fixed;
  top: 3%;
  left: 0px;
  z-index: 999;
  /* height: 20vh !important; */
  width: 20vw !important;
  margin-left: 43vw;
  border-radius: 2%;
  /* background: rgba(0, 0, 0, 0.2); */
  background-color: #fafafa;
  font-size: 1.3vw;
  padding-bottom: 3%;
  font-weight: bold;
  border: solid 3px black;
  border-radius: 10px;
}

.hidden {
    display: none;
}

.checkboxBackground {
    align-items: center;
}

.successCircle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .success {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  
  .successShape {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
  }

  @media only screen and (max-width: 978px) {
    .container {
        margin-left: 35%;
    }
  }

  @media only screen and (max-width: 420px) {
    .container {
        margin-left: 11%;
    }
  }