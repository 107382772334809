.people-itglue-export-btn {
  margin-top: 3%;
  margin-bottom: 3%;
}

.mail-logo {
  max-width: 20px;
  height: auto;
}

.mail-logo:hover {
  max-width: 22px;
  height: auto;
  cursor: pointer;
}