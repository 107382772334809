#secondary {
  background-color: #6c757d;
  color: white;
  font-size: 0.4;
}

#primary {
  background-color: #007bff;
  color: white;
  font-size: 0.4;
  margin-left: 2%;
}

.newTicketModal {
  width: 60%;
  max-width: none !important;
}

.labelField {
  font-size: 1.3em;
}

.formField {
  font-size: 1em;
  margin-bottom: 5%;
}

.labelFieldSecondary {
  font-size: 1.3em;
}

.labelField:after {
  content: "*  ";
  color: red;
}

.labelDetails {
  padding-top: 2%;
  padding-bottom: 20%;
}

.datePicker {
  /* width: 100%; */
  padding-bottom: 2%;
  font-size: 1.3em;
  border-color: #ced4da;
  border-radius: 5px;
  border-width: 1.3px;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.ticketBtns {
  width: 90%;
  display: flex;
  flex-direction: row;
}

.refreshTickets {
  margin-left: 1%;
}

.ticket-create-select-template {
  width: 50%;
  margin-bottom: 1%;
}

.ticket-create-row-customer-title {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-create-row-assignee {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-create-row-dates {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-create-row-priority-status {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-create-row-date-units-activity{
  width: 100%;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 978px) {
  .labelField {
    width: 100%;
  }

  .formField {
    width: 100%;
  }

  .ticket-create-select-template {
    width: 100%;
    margin-bottom: 1%;
  }

  .ticket-create-row-customer-title {
    flex-direction: column;
  }

  .ticket-create-row-assignee {
    flex-direction: column;
  }

  .ticket-create-row-dates {
    flex-direction: column;
  }

  .ticket-create-row-priority-status {
    flex-direction: column;
  }

  .ticket-create-row-date-units-activity{
    flex-direction: column;
  }

  .newTicketModal {
    width: 95%;
  }
}