.ws-notification {
  display: block;
  position: fixed;
  top: 6%;
  right: -40%;
  z-index: 998;
  width: 15vw !important;
  border-radius: 2%;
  font-size: 1.2em;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  border: solid 3px black;
  border-radius: 10px;
  color: black !important;
  margin-right: 1%;
  background-color: white;
  animation: display-notification 8s forwards;
}

.ws-notification-success {
    display: block;
    position: fixed;
    top: 6%;
    right: -40%;
    z-index: 998;
    width: 15vw !important;
    border-radius: 2%;
    font-size: 1.2em;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    border: solid 3px black;
    border-radius: 10px;
    margin-right: 1%;
    background-color: rgb(49, 156, 49);
    animation: display-notification 8s forwards;
}

.ws-notification-hide {
    display: none;
}

.ws-notification-message {
  color: white;
  text-align: center;
}

@keyframes display-notification {
    20% { right: 0; }
    80% { right: 0; }
    100% { right: -40%; }
}