.timesheet-export-container {
  width: 95%;
  margin: 0 auto;
}

.MTableBodyRow-root {
  background-color: black;
}

.tableHeader {
  margin: 0 auto;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 2.5%;
}

.newTicket {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 1%;
}

.dlCSV {
  margin-bottom: 2%;
  width: 10% !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
}

.dlCSVNone {
  display: none;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.timesheet-export-container > div > div > div > div > div > table > thead > tr > th:nth-child(12) {
  min-width: 30vw !important;
}