/* .ws-doc {
    width: 60%;
    margin: 0 auto;
} */

.ws-doc-btn-container {
    width: 5vw;
    align-self: center !important;
}

.ws-doc-btn-container :hover {
    cursor: pointer;
}

.ws-doc-btn {
    color: white;
}

.ws-doc-default {
    display: flex;
    position: fixed;
    background-color: #4f7822;
    top: 10%;
    right: -46%;
    z-index: 997;
    width: 50vw !important;
    border-radius: 2%;
    font-size: 1.2em;
    border: solid 3px black;
    border-radius: 10px;
    margin-right: 1%;
}

.ws-doc-hiding {
    display: flex;
    position: fixed;
    background-color: #4f7822;
    top: 10%;
    right: -46%;
    z-index: 997;
    width: 50vw !important;
    border-radius: 2%;
    font-size: 1.2em;
    border: solid 3px black;
    border-radius: 10px;
    margin-right: 1%;
    animation: display-doc-hide 2s backwards;
}

@keyframes display-doc-hide {
    0% {
        right: 0%;
    }
    100% {
        right: -46%;
    }
}

.ws-doc {
    display: flex;
    position: fixed;
    background-color: #4f7822;
    top: 10%;
    right: -46%;
    z-index: 997;
    width: 50vw !important;
    border-radius: 2%;
    font-size: 1.2em;
    border: solid 3px black;
    border-radius: 10px;
    margin-right: 1%;
    animation: display-doc 2s forwards;
}

@keyframes display-doc {
    0% {
        right: -46%;
    }
    100% {
        right: 0%;
    }
}

.ws-doc-inner {
    width: 100%;
}

.ws-doc-tab-bar {
    align-items: center !important;
    background-color: white !important;
    color: black !important;
    border-bottom: solid black 3px !important;
}

.ws-doc-tab-bar:active {
    border-bottom: solid black 3px !important;
}

div.ws-doc-inner>header>div>div>span {
    background-color: black;
    font-weight: bold;
}

@media only screen and (max-width: 1600px) {
    .ws-doc-default {
        display: none
    }
    .ws-doc {
        display: none
    }
    .ws-doc-hiding {
        display: none
    }
}