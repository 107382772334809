@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.sideBarBody {
  background-color: #f7f7f7;
  min-width: 230px;
  width: 12%;
  max-width: 230px;
  font-family: "Montserrat", sans-serif;
}

.stickySideBar {
  position: fixed;
  min-width: 230px;
  width: 9%;
  max-width: 230px;
  background-color: #f7f7f7;
}

.sideBtn {
  width: 100%;
  color: black !important;
  justify-content: flex-start !important;
  font-size: 0.45em !important;
}

.timesheetTracker {
  color: black !important;
  font-size: 0.65em;
}

.timesheetTrackerBody {
  color: black !important;
  font-size: 0.55em;
}

.sideBtn:hover {
  background-color: white !important;
  border-color: #424345 !important;
}

.sideBtnBtm {
  width: 100%;
  color: black !important;
  font-size: 0.45em !important;
  justify-content: flex-start !important;
}

.sideBtnBtm:hover {
  background-color: white !important;
  font-size: 0.45em !important;
}

.sideBtnBtmPassword {
  width: 100%;
  color: black !important;
  font-size: 0.45em !important;
  justify-content: flex-start !important;
}

.sideBtnBtmPassword:hover {
  background-color: white !important;
  font-size: 0.45em !important;
}

.iconStart {
  margin-right: 5%;
}

.iconEnd {
  margin-left: 5%;
}

.unrelatedObjects {
  margin-top: 10%;
}

.userSettings {
  margin-top: 10%;
}

@media only screen and (max-width: 1377px) {
  .stickySideBar {
    width: 12%;
  }
}

@media only screen and (max-width: 978px) {
  .sideBarBody {
    display: none;
  }

  .stickySideBar {
    display: none;
  }

  .sideBtnBtmPassword {
    font-size: 0.7em !important;
    color: white !important;
  }
}

/* Animations */

.sidebar-dropdown-default {
  width: 100%;
  border-bottom: solid 2px black;
  border-top: solid 2px black;
  padding-bottom: 0vh;
}

.sidebar-dropdown-item-default {
  display: none;
}

.sidebar-dropdown-tickets-show {
  width: 100%;
  border-bottom: solid 2px black;
  border-top: solid 2px black;
  padding-bottom: 0vh;
  z-index: 999;
  /* animation: display-dropdown-tickets 0.5s forwards; */
}

@keyframes display-dropdown-tickets {
  100% {
    padding-bottom: 20vh
  }
}

.sidebar-dropdown-tickets-collapse {
  width: 100%;
  border-bottom: solid 2px black;
  border-top: solid 2px black;
  padding-bottom: 20vh;
  z-index: 999;
  /* animation: collapse-dropdown-tickets 2s forwards; */
}

@keyframes collapse-dropdown-tickets {
  50% {
    padding-bottom: 20vh;
  }

  100% {
    padding-bottom: 0vh
  }
}

.sidebar-dropdown-all-tickets-show {
  width: 100%;
  position: absolute;
  top: 10vh;
  visibility: hidden;
  opacity: 0;
  /* animation: display-dropdown-all-tickets 1.75s forwards; */
}

@keyframes display-dropdown-all-tickets {
  60% {
    top: 10vh;
  }

  100% {
    opacity: 1;
    transition: opacity 7s ease-in-out;
    visibility: visible;
    top: 13vh
  }
}

.sidebar-dropdown-all-tickets-collapse {
  width: 100%;
  position: absolute;
  top: 13vh;
  visibility: visible;
  opacity: 1;
  /* animation: collapse-dropdown-all-tickets 0.75s forwards; */
}

@keyframes collapse-dropdown-all-tickets {
  100% {
    opacity: 0;
    transition: opacity 2s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}

.sidebar-dropdown-userspace-show {
  width: 100%;
  position: absolute;
  top: 10vh;
  visibility: hidden;
  opacity: 0;
  /* animation: display-dropdown-userspace 1.5s forwards; */
}

@keyframes display-dropdown-userspace {
  50% {
    top: 10vh;
  }

  100% {
    opacity: 1;
    transition: opacity 6s ease-in-out;
    visibility: visible;
    top: 17vh
  }
}

.sidebar-dropdown-userspace-collapse {
  width: 100%;
  position: absolute;
  top: 17vh;
  visibility: visible;
  opacity: 1;
  /* animation: collapse-dropdown-userspace 1s forwards; */
}

@keyframes collapse-dropdown-userspace {
  25% {
    top: 17vh;
  }

  100% {
    opacity: 0;
    transition: opacity 4s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}

.sidebar-dropdown-favourite-show {
  width: 100%;
  position: absolute;
  top: 10vh;
  visibility: hidden;
  opacity: 0;
  /* animation: display-dropdown-favourite 1.25s forwards; */
}

@keyframes display-dropdown-favourite {
  40% {
    top: 10vh;
  }

  100% {
    opacity: 1;
    transition: opacity 5s ease-in-out;
    visibility: visible;
    top: 21vh
  }
}

.sidebar-dropdown-favourite-collapse {
  width: 100%;
  position: absolute;
  top: 21vh;
  visibility: visible;
  opacity: 1;
  /* animation: collapse-dropdown-favourite 1.25s forwards; */
}

@keyframes collapse-dropdown-favourite {
  40% {
    top: 21vh;
  }

  100% {
    opacity: 0;
    transition: opacity 5s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}

.sidebar-dropdown-template-add-show {
  width: 100%;
  position: absolute;
  top: 10vh;
  visibility: hidden;
  opacity: 0;
  /* animation: display-dropdown-template-add 1s forwards; */
}

@keyframes display-dropdown-template-add {
  25% {
    top: 10vh;
  }

  100% {
    opacity: 1;
    transition: opacity 4s ease-in-out;
    visibility: visible;
    top: 25vh
  }
}

.sidebar-dropdown-template-add-collapse {
  width: 100%;
  position: absolute;
  top: 25vh;
  visibility: visible;
  opacity: 1;
  /* animation: collapse-dropdown-template-add 1.5s forwards; */
}

@keyframes collapse-dropdown-template-add {
  50% {
    top: 25vh;
  }

  100% {
    opacity: 0;
    transition: opacity 6s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}

.sidebar-dropdown-template-edit-show {
  width: 100%;
  position: absolute;
  top: 10vh;
  visibility: hidden;
  opacity: 0;
  /* animation: display-dropdown-template-edit 0.75s forwards; */
}

@keyframes display-dropdown-template-edit {
  25% {
    top: 10vh;
  }

  100% {
    visibility: visible;
    opacity: 1;
    transition: opacity 3s ease-in-out;
    top: 29vh
  }
}

.sidebar-dropdown-template-edit-collapse {
  width: 100%;
  position: absolute;
  top: 29vh;
  visibility: visible;
  opacity: 1;
  /* animation: collapse-dropdown-template-edit 1.75s forwards; */
}

@keyframes collapse-dropdown-template-edit {
  60% {
    top: 29vh;
  }

  100% {
    opacity: 0;
    transition: opacity 7s ease-in-out;
    visibility: hidden;
    top: 10vh
  }
}