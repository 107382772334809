.accounts-add-new-btn {
  width: 12%;
  display: inline-block;
  font-size: 0.6em;
  margin: 1%;
  padding: 0.5%;
  border: 3px solid black;
  border-radius: 10px;
  cursor: pointer;
}

.accounts-add-form {
  width: 30%;
  margin: 0 auto;
  background-color: #3b6db3;
  border-radius: 3%;
  padding: 1.5%;
}

.accounts-add-label {
  width: 100%;
  font-size: 0.6em;
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.accounts-add-label-required {
  width: 100%;
  font-size: 0.6em;
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.accounts-add-label-required:after {
  content: "*  ";
  color: red;
}

.accounts-add-field {
    width: 100%;
    font-size: 0.8em;
    border: solid 0px;
    border-radius: 8px;
}