@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.info {
  display: flex;
  flex-direction: row;
  /* align-items: baseline; */
  width: 100%;
  background-color: white;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  font-size: 0.5em;
  font-family: "Montserrat", sans-serif;
  border-bottom: 3px solid #9ecf67;
}

.stickyCover {
  position: fixed;
  top: 9%;
  width: 9%;
  max-width: 230px;
  height: 100%;
  background-color: #f7f7f7;
}

.navBarLogo {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /* margin-left: 10%; */
  color: white;
}

/* .navBarLogo:before {
  content: "Country Consulting Admin Area";
} */

.user {
  display: flex;
  justify-content: flex-end;
  width: 30%;
  margin-right: 1%;
  color: black;
}

.settings {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: white !important;
  border-color: white !important;
}

.pwBtn {
  color: white !important;
  border-color: white !important;
}

.tfaBtn {
  color: white !important;
  border-color: white !important;
}

.navBtn {
  color: white !important;
  border-color: white !important;
  margin-left: 5% !important;
}

.navBtnExport {
  color: white !important;
  border-color: white !important;
  margin-left: 2% !important;
}

.timer {
  color: white !important;
  border-color: white !important;
  margin-left: 2% !important;
  margin-right: 1% !important;
}

.hide {
  visibility: hidden;
}

.dropdown {
  display: none;
}

/* #navBarLogo {
  display: none;
} */

@media only screen and (max-width: 978px) {
  .stickyCover {
    display: none;
  }

  .info {
    padding-bottom: 2%;
    background-color: #2e2e2e;
  }

  .navBarLogo {
    margin-left: 2%;
  }

  .navBarLogo:before {
    content: none;
  }

  #navBarLogo {
    display: flex;
    margin-top: 5%;
    width: 220px;
    height: 24px;
  }

  .user {
    font-size: 1.5em;
    width: 100%;
    margin-top: 3%;
  }

  .dropdown {
    display: flex;
    color: white;
    margin-top: 1%;
    margin-right: 1.5%;
  }

  #dropdownMobile {
    background-color: #2e2e2e;
    border: 0px;
    box-shadow: none;
    text-align: center;
    justify-items: center;
    justify-self: center;
  }

  #dropdownMobile::after {
    display: none !important;
  }

  .dropdownBtn {
    background-color: #3f51b5;
    padding: 2px;
    border-radius: 5px;
  }

  .navBarBtnMobile {
    color: white !important;
    border-top: solid 1px !important;
    border-bottom: solid 1px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-radius: 0px !important;
    border-color: #2d2e2f !important;
    background-color: black !important;
    text-align: center !important;
    font-size: 0.7em !important;
    width: 100%;
  }

  .dropdown-menu {
    background-color: #1b1c14;
  }

  .iconStart {
    margin: 0;
  }
}

@media only screen and (max-width: 420px) {
  #lognavBarLogo {
    width: 220px;
    height: 24px;
  }
}