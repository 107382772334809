@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.dashboard-body {
  background-color: #f4f7ff;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
  color: black;
  overflow-x: hidden;
}

.dashboard-background {
  width: 100%;
}

.dashboard-outer {
  width: 100%;
  max-height: fit-content;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
}

.dashboard-inner {
  width: 90%;
  margin: 0 auto;
  margin-top: 2%;
}

.dashboard-title {
  margin: 0 auto;
  font-weight: bold;
}

.dashboard-main-area {
  display: flex;
  flex-direction: row;
}

.unclaimedTasksAnnounce {
  width: 60%;
  margin: 0 auto;
  background-color: #db8f84;
  border-radius: 10px;
  margin-bottom: 5%;
  padding: 1%;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1.2em;
}

.unclaimedTasksAnnounceTV {
  width: 60%;
  margin: 0 auto;
  background-color: #db8f84;
  border-radius: 10px;
  margin-bottom: 5%;
  padding: 1%;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1em;
}

.timesheetDatePicker {
  width: 13vw;
  max-width: 150px;
  margin: 0 auto;
  font-size: 0.8em;
  text-align: center;
  margin-bottom: 1%;
}

.timesheetDatePickerDates {
  width: 40%;
  margin: 0 auto;
  font-size: 0.8em;
  margin-bottom: 2%;
}

.unclaimedTasksAnnounceIcon {
  width: 5vw;
  max-width: 80px;
  margin: 0 auto;
  border-radius: 100%;
  padding: 1%;
  background-color: #a65044;
}

.dashboard-widgets-chart-area {
  width: 100%;
}

.doughnut-timesheets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}

.doughnut-timesheets-weekend {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 40%;
  margin: 0 auto;
  margin-top: 2%;
}

.timesheet-chart-weekend {
  width: 45%;
  box-shadow: 1px 1px 5px 3px grey;
  padding-bottom: 1%;
  font-size: 0.7em;
  font-family: "Montserrat", sans-serif;
  background-color: #fafafa;
}

.timesheet-chart-weekend:hover {
  cursor: pointer;
}

.timesheet-chart {
  width: 19%;
  box-shadow: 1px 1px 5px 3px grey;
  padding-bottom: 1%;
  font-size: 0.7em;
  font-family: "Montserrat", sans-serif;
  background-color: #fafafa;
}

.timesheet-chart:hover {
  cursor: pointer;
}

.timesheets-graph-header {
  width: 100%;
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2%;
}

.charts {
  width: 100%;
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
}

.chartjs-render-monitor {
  background-color: #fafafa;
}

.widget-body {
  display: flex;
  flex-direction: column;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  font-size: 0.8em;
  width: 12vw;
  max-width: 220px;
  /* border-radius: 3%;
  border: solid 3px black; */
  font-family: "Montserrat", sans-serif;
  color: black;
  font-weight: bold;
  background-color: white;
  box-shadow: 1px 1px 5px 3px grey;
}

.widget-body:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}

.widget-data {
  font-size: 2em;
}

.widget-title {
  font-size: 0.5em;
  margin-top: auto;
  color: #878787;
}

.widget-icon {
  width: 30%;
  max-width: 50px;
  margin: 0 auto;
  border-radius: 100%;
  padding: 3%;
}

.widget-icon>div {
  padding: 0%;
}

.dashboard-widgets {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 10%;
  padding-right: 10%;
}

.table-client-parent {
  width: 100%;
  margin: 0 auto;
  margin-left: 1%;
}

.table-client {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  background-color: white;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.table-header-client {
  background-color: #d5eef2;
  padding: 2%;
  font-size: 0.9em;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  /* border-bottom: solid 2px black; */
}

.table-column-headers-client {
  font-size: 0.6em;
  padding-left: 1%;
  padding-right: 1%;
}

.table-client-td {
  padding-left: 3.75%;
  padding-right: 3.75%;
}

.table-client-row>td {
  font-size: 0.5em;
  /* border: solid 2px grey; */
  padding-top: 0.75%;
  padding-bottom: 0.75%;
}

.timesheet-employee-name {
  width: 100%;
  margin: 0 auto;
}

.charts-new-tickets {
  width: 50%;
}

.TVUnclaimedTasksListContainer {
  width: 100%;
  margin-left: 25%;
  margin-bottom: 5%;
}

.TVUnclaimedTasksList {
  font-size: 0.5em;
  text-align: justify;
}

.critical-tickets-table {
  width: 80%;
  margin: 0 auto;
  margin-top: 2%;
  margin-bottom: 2%;
}

.tickets-by-timesheets-table {
  width: 80%;
  margin: 0 auto;
  margin-top: 2%;
  margin-bottom: 2%;
}

.tickets-by-timesheets-table>div>div>div>div>div>table>thead>tr>th:nth-child(6) {
  min-width: 40vw !important;
}

@media only screen and (max-width: 1600px) {
  .doughnut-timesheets {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  .timesheet-chart {
    width: 30%;
    margin-top: 2%;
  }
  .charts {
    width: 90%;
    align-items: center;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
  }
  .charts-new-tickets {
    width: 90%;
    margin-bottom: 5%;
  }
  .table-client-parent {
    width: 100%;
  }
  .table-client {
    width: 90% !important;
    margin: 0 auto;
  }
  .table-header-client {
    font-size: 1em;
  }
  .table-column-headers-client {
    font-size: 0.65em;
    padding-left: 0%;
    padding-right: 0%;
  }
  .table-client-td {
    padding-left: 0%;
    padding-right: 0%;
  }
  .table-client-row>td {
    font-size: 0.65em;
    padding-top: 0.75%;
    padding-bottom: 0.75%;
  }
  #root>div>header>div>div.dashboard-inner>div:nth-child(2) {
    width: 100%;
  }
  .tickets-by-timesheets-table {
    width: 80%;
    align-items: center;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 1270px) {
  .doughnut-timesheets {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-betweem;
  }
  .timesheet-chart {
    width: 45%;
    margin-top: 2%;
  }
}

@media only screen and (max-width: 978px) {
  .dashboard-widgets {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .widget-body {
    flex-direction: column;
    margin-bottom: 3%;
    font-size: 1.6em;
    width: 100%;
  }
  .dashboard-title {
    font-size: 2em;
    margin-bottom: 10%;
  }
  .unclaimedTasksAnnounce {
    width: 100%;
    font-size: 1em;
  }
  .unclaimedTasksAnnounceIcon {
    width: 10%;
  }
  .doughnut-timesheets {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
  }
  .timesheet-chart {
    width: 100%;
    margin-bottom: 5%;
    box-shadow: 1px 1px 5px 3px grey;
    padding-bottom: 1%;
    font-size: 1em;
    font-family: "Montserrat", sans-serif;
    background-color: #fafafa;
  }
  .timesheet-employee-name {
    font-size: 1.6em;
  }
  .timesheets-graph-header {
    font-size: 1.5em;
  }
  .timesheetDatePicker {
    width: 50%;
    font-size: 1.2em;
  }
  .timesheetDatePickerDates {
    width: 100%;
    font-size: 1em;
  }
  .widget-body {
    width: 50vw;
    max-width: 50vw;
  }
  .widget-icon {
    max-width: 20vw;
  }
  .tickets-by-timesheets-table {
    width: 90%;
  }
}