/* .container {
    position: fixed;
    top: 9%;
    left: 0px;
    z-index: 999;
    height: 20%;
    max-width: 300px;
    margin-left: 48.5%;
    border-radius: 2%;
    background: rgba(0, 0, 0, 0.2);
    background-color: #fafafa;
    font-size: 0.7em;
  } */

  .container-error {
    position: fixed;
    top: 3%;
    left: 0px;
    z-index: 999;
    /* height: 20vh !important; */
    width: 20vw !important;
    margin-left: 40vw;
    border-radius: 2%;
    /* background: rgba(0, 0, 0, 0.2); */
    background-color: #fafafa;
    font-size: 1.3vw;
    padding-bottom: 3%;
    font-weight: bold;
    border: solid 3px black;
    border-radius: 10px;
  }
  
  .hidden {
      display: none;
  }
  
  .errorBackground {
      align-items: center;
  }

.error__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #ff7a7a;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .error {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #ff7a7a;
    animation: fillError .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  
  .error__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fillError {
    100% {
      box-shadow: inset 0px 0px 0px 30px #ff7a7a;
    }
  }