.people-update-btn {
  color: #56878f;
  font-weight: bolder;
  font-size: 1.1em;
  cursor: pointer;
}

.people-update-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.people-update-modal-open {
  width: 45%;
  max-height: 90vh !important;
  background-color: white;
  border: 2px solid #000;
  margin-left: 10%;
  text-align: center;
  font-weight: bold;
  padding: 1%;
}

.people-update-new-btn {
  width: 12%;
  display: inline-block;
  font-size: 1em;
  margin: 1%;
  padding: 0.5%;
  border: 3px solid black;
  border-radius: 10px;
  cursor: pointer;
}

.people-update-form {
  width: 100%;
  margin: 0 auto;
  background-color: #3b6db3;
  border-radius: 3%;
  padding: 1.5%;
}

.people-update-label {
  width: 100%;
  font-size: 1em;
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  padding-top: 1%;
}

.people-update-label-required {
  width: 100%;
  font-size: 1em;
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  padding-top: 1%;
}

.people-update-label-required:after {
  content: "*  ";
  color: red;
}

.people-update-field {
  width: 100%;
  font-size: 1em;
  border: solid 0px;
  border-radius: 8px;
}

.people-update-row {
  display: flex;
  justify-content: space-between;
  padding-top: 1%;
}

.people-update-ph-notes {
  display: flex;
}

.people-update-col-phone {
  justify-content: left;
  padding-top: 1%;
  width: 100%;
}

.people-update-col-notes {
  width: 100%;
  margin-left: 3%;
  padding-top: 1%;
}

.people-update-row-name {
  width: 48% !important;
}

.notes {
  width: 100%;
  height: 13vh;
}
