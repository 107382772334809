.login-form {
  width: 100%;
  padding: 15%;
  background-color: white;
}

.login-form-field {
  font-size: 1em;
  width: 100%;
  margin-bottom: 5%;
}

#logo {
  width: 100%;
  display: flex;
}

.btn {
  width: 70%;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
}

.login-btn {
  display: flex;
  justify-content: center;
}

.spinning-icon {
  margin-left: 5%;
}

.spinning-icon-hide {
  display: none;
}

.login-error {
  color: #990000;
  font-size: 0.6em;
  font-weight: bold;
}

.login-success {
  color: #2ad613;
  font-size: 0.6em;
  font-weight: bold;
}

/* Overriding Material UI */

.home-login-btn {
  width: 40% !important;
  padding: 5% !important;
  color: white !important;
  background-color: #4f7822 !important;
}

.home-login-btn:hover {
  background-color: #395719 !important;
}

.login-form-tfa-hide {
  display: none;
}