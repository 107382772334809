#secondary {
  background-color: #6c757d;
  color: white;
  font-size: 0.4;
}

#primary {
  background-color: #007bff;
  color: white;
  font-size: 0.4;
  margin-left: 2%;
}

.labelField {
  font-size: 1.3em;
}

.formField {
  font-size: 1em;
  margin-bottom: 5%;
}

.labelFieldSecondary {
  font-size: 1.3em;
}

.labelField:after {
  content: "*  ";
  color: red;
}

.labelDetails {
  padding-top: 2%;
  padding-bottom: 20%;
}

.modalTitle {
  text-align: center;
  align-self: center;
}

#deleteTicket {
  background-color: red;
  color: white;
  justify-content: start;
  margin-right: 10%;
}

#controlled-tab-example-tabpane-myob
  > div.modal-body
  > div
  > div
  > div.MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-74.MuiToolbar-gutters {
  min-height: 0px;
}

.tickets-edit-table
  > div
  > div
  > div
  > div
  > div
  > table
  > thead
  > tr
  > th:nth-child(4) {
  min-width: 1vw !important;
}

.tickets-edit-table
  > div
  > div
  > div
  > div
  > div
  > table
  > thead
  > tr
  > th:nth-child(6) {
  min-width: 20vw !important;
}

.tickets-edit-table
  > div
  > div
  > div
  > div
  > div
  > table
  > thead
  > tr
  > th:nth-child(7) {
  min-width: 15vw !important;
}

.addTime {
  text-align: center;
  width: 90%;
  margin-top: 5%;
}

.addTimeBtn {
  padding-left: 7% !important;
  padding-right: 7% !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
}

.refreshTimesheets {
  text-align: center;
  width: 100%;
  margin-bottom: 3%;
  margin-top: 5%;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.ticket-edit-row-customer-title {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-edit-row-assignee {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-edit-row-dates {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-edit-row-priority-status {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ticket-edit-row-date-units-activity {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.editTicketModal {
  width: 60%;
  max-width: none !important;
}

@media only screen and (max-width: 978px) {
  .ticket-edit-row-customer-title {
    flex-direction: column;
  }

  .ticket-edit-row-assignee {
    flex-direction: column;
  }

  .ticket-edit-row-dates {
    flex-direction: column;
  }

  .ticket-edit-row-priority-status {
    flex-direction: column;
  }

  .ticket-edit-row-date-units-activity {
    flex-direction: column;
  }

  .editTicketModal {
    width: 95%;
  }
}