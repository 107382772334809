.admin-body {
  background-color: rgba(255, 255, 255, 0.973);
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
  color: black;
}

.userspace-body-internal {
  width: 100%;
  max-height: fit-content;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
}

.userspace-content {
  width: 100%;
  display: column !important;
}

.page-header {
  margin: 0 auto;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 2.5%;
  font-weight: bold;
}

.task-details-container {
  width: 33%;
  line-height: 1;
}

.tasks-user {
  width: 90%;
  margin: 0 auto;
  display: flex;
  text-align: left;
  margin-bottom: 2%;
  font-size: 1em
}

.tasks-account-name {
  font-weight: bold !important;
}

.task-account-title {
  font-size: 0.5em;
}

.task-title-unique {
  text-align: center;
  font-weight: bold;
}

@media only screen and (max-width: 1270px) {
  .tasks-user {
    flex-direction: column;
    align-items: center;
    margin-right: 10%;
  }
  .task-details-container {
    width: 90%;
    text-align: center;
    font-size: 1.4em;
  }
}