.tickets {
  width: 95%;
  margin: 0 auto;
}

.ticketTimesTable {
  width: 95%;
  margin: 0 auto;
}

.MTableBodyRow-root {
  background-color: black;
}

.tableHeader {
  margin: 0 auto;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 2.5%;
  font-weight: bold;
}

.newTicket {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 1%;
  margin-left: 3%;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.completedTicketsHide {
  display: flex;
  font-size: 0.8em;
  justify-content: flex-start;
  margin-left: 5%;
  border-style: solid !important;
  border: 5px !important;
  border-color: black;
}

.centerTabs {
  display: 'flex';
  justify-content: 'center';
  width: 700px;
}

.datePicker {
  padding-top: 10px;
  padding-left: 10px;
  font-size: xx-large;
}

.loading-overlay {
  z-index: 999;
  /* Ensure it's on top of everything */
  color: #fff;
  /* Color for the spinner */
}

.tickets>div>div>div>div>div>table>thead>tr>th:nth-child(1) {
  min-width: 1vw !important;
}

.tickets>div>div>div>div>div>table>thead>tr>th:nth-child(2) {
  min-width: 30vw !important;
}

.tickets>div>div>div>div>div>table>thead>tr>th:nth-child(3) {
  min-width: 15vw !important;
}

.ticketTimesTable>div>div>div>div>div>table>thead>tr>th:nth-child(7) {
  min-width: 30vw !important;
}

@media only screen and (max-width: 1499px) {
  .tickets {
    width: 90%;
  }
}

@media only screen and (max-width: 1377px) {
  .tickets {
    width: 90% !important;
  }

  .newTicket {
    margin-left: 5%;
  }
}