.accounts-display-container {
  width: 100%;
  margin: 0 auto;
}

.accounts-display-table {
  width: 50%;
  font-size: 0.6em;
  margin: 0 auto;
  margin-top: 5%;
}

.accounts-filter-by-letter {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  width: 70%;
  margin: 0 auto !important;
  text-align: center !important;
  color: #56878f;
  font-size: 1.5vw;
  font-weight: 400;
}

.accounts-filter-by-letter > div {
  width: 5%;
}

.accounts-filter-by-letter > div:hover {
  cursor: pointer;
}

.accounts-contact-search-bar {
  margin: 0 auto;
  width: 70%;
  font-size: 1.5vw;
  font-weight: 400;
}

.accounts-contact-information {
  
  flex-direction: column;
  align-items: center;
  font-size: 0.6em;
  text-align: left;
  width: 100%;
}
.accounts-update-new-btn {
  width: 12%;
  display: inline-block;
  font-size: 0.6em;
  margin: 1%;
  padding: 0.5%;
  border: 3px solid black;
  border-radius: 10px;
  cursor: pointer;
}

.accounts-contact-view-details {
  text-align: right;
}

.accounts-contact-view-details-info {
  text-align: center;
}