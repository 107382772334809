.leave_body {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.leave_body h1 {
  margin-top: 30px;
  font-size: 50px;
}

.leave_body h2 {
  margin-top: 30px;
  text-align: left;
}

.leave_btnContainer {
  width: 100%;
}

.leave_body th,
.leave_body td {
  font-size: 20px !important;
  max-width: 30vh;
  vertical-align: middle !important;
}

.leave_btnContainer Button {
  margin: 3vh 3vh;
  width: 20vh;
  height: 60px;
  background-color: #3f51b5;
  color: white;
}

.leave_body Table Button,
.view-account-contacts-modal Form Button {
  margin: 10px 10px;
  width: 7vw;
  height: 35px;
  background-color: #3f51b5;
  color: white;
}

.leave_body Table Button :hover,
.view-account-contacts-modal Form Button :hover {
  margin: 10px 10px;
  width: 7vh;
  height: 35px;
  opacity: 0.5;
  color: white;
}

.view-account-contacts-modal-open Form {
  width: 25vw;
  text-align: left;
  margin: auto;
}

.view-account-contacts-modal-open {
  align-content: center;
}

.leave_dateForm {
  width: 15vw !important;
  display: inline-table;
  padding-left: 1vh;
  padding-right: 1vh;
  text-align: left;
  font-size: large;
}