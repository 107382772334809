@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.tasks {
  width: 95%;
  margin: 0 auto;
}

.viewDetails {
  color: #56878f;
  font-weight: bolder;
  font-size: 1.1em;
  cursor: pointer;
}

.MTableBodyRow-root {
  background-color: black;
}

.tableHeader {
  margin: 0 auto;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 2.5%;
  font-weight: bold;
}

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.tabsTasks {
  width: 100%;
  margin-bottom: 3%;
}

/* #controlled-tab-example-tab-inProgress {
  background-color: #ffffc7;
  color: black;
}

#controlled-tab-example-tab-completed {
  background-color: #c7ffcb;
  color: black;
} */

div.tabsTasks > nav {
  margin: 0 auto;
  justify-content: center;
  font-size: 0.7em;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

div.tabsTasks > nav > .nav-item {
  background-color: #3b6db3;
  color: white;
  padding: 2%;
  margin: 0.3%;
}

div.tabsTasks > nav > .nav-link.active {
  background-color: white;
  color: black;
  border: 3px black solid;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-ignore-onclickoutside {
  width: 100%;
}

.react-datepicker__input-container > input {
  width: 100%;
}

.detailsHeader {
  font-weight: bold;
  font-size: 1.2em;
  width: 100%;
}

.detailsBtns {
  display: flex;
  margin-top: 10%;
}

.tasks-btn {
  width: 20%;
  background-color: #009400;
  color: white;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  padding: 1%;
  cursor: pointer;
  font-weight: bold;
}

.taskAddForm {
  width: 30%;
  margin: 0 auto;
  background-color: #3b6db3;
  border-radius: 3%;
  padding: 3%;
}

.taskLabelField {
  width: 100%;
  font-size: 0.6em;
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.taskLabelField:after {
  content: "*  ";
  color: red;
}

.taskAddSubmit {
  width: 7.5%;
  margin: 0 auto;
  padding: 0.5% 0.25%;
  margin-top: 1%;
  margin-bottom: 5%;
  font-size: 0.7em;
  cursor: pointer;
  background-color: #3f51b5;
  color: white;
  font-weight: bold;
  border-radius: 3px;
}

.taskAddSubmit:hover {
  background-color: #374594;
}

@media only screen and (max-width: 1499px) {
  .tickets {
    width: 90%;
  }
}

@media only screen and (max-width: 1377px) {
  .tickets {
    width: 90% !important;
  }

  .newTicket {
    margin-left: 5%;
  }
}

@media only screen and (max-width: 978px) {
  .taskAddForm {
    width: 90%;
  }

  .taskLabelField {
    font-size: 0.9em;
  }

  .taskAddSubmit {
    width: 50%;
    padding: 5%;
  }

  .tasks-btn {
    width: 40%;
    padding: 5%;
  }
}