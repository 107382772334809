.view-log-btn {
  color: #56878f;
  font-weight: bolder;
  font-size: 1.1em;
  cursor: pointer;
  text-align: center;
}

.view-log-modal {

  align-items: center;
  justify-content: center;
}

.view-log-modal-open {
  width: 80% !important;
  max-height: 90vh !important;
  background-color: white;

  margin-left: 10%;
  text-align: center;
  font-weight: bold;
  padding: 0%;
  align-items: center;
}

.view-log-modal-open > div > div > div > div > div > table > tbody > tr > td:nth-child(2) {
    width: 70% !important;
}