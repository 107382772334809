.view-account-contacts-btn {
    cursor: pointer;
  }
  
  .view-account-contacts-modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .view-account-contacts-modal-open {
    width: 40%;
    max-height: 90vh !important;
    background-color: white;
    border: 2px solid #000;
    margin-left: 10%;
    text-align: center;
    font-weight: bold;
    padding: 1%;
    overflow-y: scroll;
  }

  .account-contacts-name {
    color: #56878f;
  }