.view-task-details-btn {
  cursor: pointer;
}

.view-task-details-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-task-details-modal-open {
  width: 40%;
  max-height: 90vh !important;
  background-color: white;
  border: 2px solid #000;
  margin-left: 10%;
  text-align: center;
  font-weight: bold;
  padding: 1%;
  overflow-y: scroll;
}

.task-details-btn {
    /* display: flex; */
}

.task-details-account {
    color: #56878f;
    font-size: 0.5em;
    font-weight: bold;
}

.task-details-title {
    font-size: 0.5em;
}